.mde-header {
  background: #fff;
}
.mde-preview {
  background: #fff;
}
.react-mde .grip {
  background: #fff;
}
.mde-header .mde-tabs button {
  padding-bottom: 0px;
  margin-bottom: 0px !important;
  padding-left: 17px;
  padding-right: 17px;
}
@media (min-width: 800px) {
  .mde-tabs {
    margin-bottom: -1px;
  }
  .mde-header .mde-tabs button.selected {
    color: #24292e;
    background-color: #fff;
    border-color: #d1d5da;
    border-width: 1px 1px 0px 1px !important;
    border-radius: 3px 3px 0 0;
  }
}
