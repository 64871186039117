#__next,
body,
html {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
/* userblock menu icons fix(logout buttom had padding from bottom) */
.material-icons {
  line-height: unset !important;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.red-btn {
  background: linear-gradient(315deg, #58424a 0, #e0003e 100%);
  color: #fff !important;
  border: 1px solid #2d73c0;
  border-radius: 2px;
}
.emoji {
  width: 28px;
  font-size: 2em;
}
@media (max-width: 768px) {
  .mobile-d-none {
    display: none !important;
  }
}
.simplebar-placeholder {
  width: 100% !important;
}
