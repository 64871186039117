.ant-col.ant-form-item-label {
  line-height: normal;
  text-align: left;
}
.ant-form-item {
  display: block;
}
.ant-form-item-label > label {
  font-size: 0.85em !important;
  font-style: inherit;
  line-height: 1.3;
  color: #6b778c !important;
  font-weight: 600;
  display: inline-block;
  height: auto !important;
}
.ant-col.ant-form-item-control {
  max-width: 100% !important;
}
.ant-form-item-label > label::after {
  content: '' !important;
}
.ant-form-item-control-input-content input {
  border-color: #dfe1e6;
  border-style: solid;
  box-sizing: border-box;
}
.ant-input:focus {
  border-color: #6fb4ef !important;
  box-shadow: none !important;
}
.ant-form-item {
  margin-bottom: 8px !important;
}
.ant-drawer-title {
  color: #fff;
}
.ant-drawer .ant-drawer-content {
  background: #21242b;
}
.ant-comment-inner {
  padding: 0 !important;
}
.ant-comment-avatar img {
  border-radius: 0px;
}
