.clearfix:after {
  clear: both;
  content: '.';
  display: block;
  font-size: 0;
  height: 0;
  line-height: 0;
  visibility: hidden;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.px-3 {
  padding-right: 1rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
}
.pl-1 {
  padding-left: 0.25rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.ml-5 {
  margin-left: 3rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.ml-5 {
  margin-left: 3rem !important;
}
.m-1 {
  margin-left: 0.25rem !important;
}
.m-auto {
  margin: auto !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.mx-auto {
  margin-right: auto !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.my-1 {
  margin-bottom: 0.25rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.d-flex {
  display: flex !important;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media print {
  .container {
    min-width: 992px !important;
  }
}
.col-sm-3,
.col-sm-5 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 576px) {
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #6c757d;
    content: '/';
  }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
  }
  .breadcrumb-item.active {
    color: #6c757d;
  }
}
.float-right {
  float: right !important;
}
.d-none {
  display: none !important;
}
.text-muted {
  color: #6c757d !important;
}
.w-100 {
  width: 100% !important;
}
h5 {
  font-size: 1.25rem;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: '/';
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
